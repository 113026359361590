$configs: (
	header: 100px,
	footer: 645px,
	sidebar: 300px,
	menu: 50px
);

$colors: (
	gray-1: #D8D8D8, 
	gray-2: #B0B0B0, 
	gray-3: #707070, 
	primary: #0E2F3A,  
	primary-dark: #06191f,  
	primary-light: #124156, 
	accent-yellow: #FDBA12, 
	accent-teal: #57A9B6,
	accent-teal-light: #6FC3D6,	
	link-blue: #11445B,
	link-blue-hover: #4784a0,
);

$fonts: (
	lato-light: ("Lato Light", sans-serif),
	lato-regular: ("Lato Regular", sans-serif),
	lato-bold: ("Lato Bold", sans-serif),
	bebas-light: ("Bebas Light", sans-serif),
	bebas-regular: ("Bebas Regular", sans-serif),
	bebas-bold: ("Bebas Bold", sans-serif),
);

$breakpoints: (
	phone: 399px,
	phone-lg: 649px,
	tablet: 767px,
	tablet-lg: 991px,
	desktop-lg: 1023px
);

@function config($key) {
	@if map-has-key($configs, $key) {
		@return map-get($configs, $key);
	}
	@warn "Unknown #{$key} in configs map";
	@return null;
}

@function color($key) {
	@if map-has-key($colors, $key) {
		@return map-get($colors, $key);
	}
	@warn "Unknown #{$key} in colors map";
	@return null;
}

@function font($key) {
	@if map-has-key($fonts, $key) {
		@return map-get($fonts, $key);
	}
	@warn "Unknown #{$key} in colors map";
	@return null;
}

@function breakpoint($key) {
	@if map-has-key($breakpoints, $key) {
		@return map-get($breakpoints, $key);
	}
	@warn "Unknown #{$key} in breakpoints map";
	@return null;
}
