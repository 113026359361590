/*------------------------------------*\
              ¡GENERAL
\*------------------------------------*/

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  margin: 0;
  padding: 0;
}

body {
  font-family: font(lato-light);
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.lk-wrap {
  @include flexbox();
  @include flex-direction(column);
  @include transition(top .5s);
  margin: 0 auto;
  position: relative;
  top: 0;
  &.show-menu {
    top: config(menu);
  }
  min-height: calc(100vh - 100px);
  // max-width: 1887px;
}

.paragraph {
  line-height: 1.2em;
}


/*------------------------------------*\
                ¡ALERT
\*------------------------------------*/

.alert {
  @include transition(all 5s);
  @include animation(slideUp 0s ease-in 5s forwards);
  background: color(accent-teal);
  color: white;
  left: 0;
  overflow: hidden;
  padding: 10px 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 5;
}

@include keyframes(slideUp) {
  0% {
    @include transform(translateY(0px));
  }
  100% {
    @include transform(translateY(unquote('-' + config(header))));
  }
}





/*------------------------------------*\
          ¡HEADER WRAP
\*------------------------------------*/


.header-wrap {
  @include flexbox();
  @include flex-direction(column);
  @include justify-content(space-between);
  overflow: hidden;
  position: relative;
  .btn-cta {
    margin-top: 80px;
    position: relative;
    z-index: 1;
    @media screen and (max-width: breakpoint(tablet-lg)) {
      margin-top: 35px;
    }
    @media screen and (max-width: breakpoint(tablet)) {
      margin-top: 15px;
    }
  }
  .for-flex-style {
    @media screen and (max-width: breakpoint(tablet-lg)) {
      @include order(3);
    }
  }
}

.is-home {
  .header-wrap {
    height: 800px;
    background-color: color(primary);
    box-shadow: inset 0 100px 400px color(primary-dark);
    @media screen and (max-width: breakpoint(phone-lg)) {
      box-shadow: none;
    }
  }
  .title-intro-container {
    height: 100%;

    .lk-squeeze {
      background-repeat: no-repeat; 
      background-position: right; 
      background-size: calc(min(48vw, 700px));
      
      @media screen and (max-width: breakpoint(tablet-lg)) {
        background-position-x: center;
        background-position-y: top;
        background-size: 500px;
      }
      @media screen and (max-width: breakpoint(phone-lg)) {
        background-position-x: left;
        background-position-y: top;
        background-size: calc(max(85vw, 500px));
      }

      .title-intro {
        padding-top: 200px;
        font-size: 60pt;
        @media screen and (max-width: breakpoint(tablet-lg)) {
          padding-top: 400px;
        }
        @media screen and (max-width: breakpoint(phone-lg)) {
          padding-top: 450px;
          font-size: 40pt;
        }
      }
      .btn {
        margin-top: 20px;
      }
      @media screen and (max-width: breakpoint(tablet-lg)) {
        @include order(1);
      }
    }
  }
}

.subpage {
  &.is-craft {
    .header-wrap {
      background-color: color(primary);
    }
    .title-intro-container {
      .title-intro {
        font-size: 40pt;
        margin-top: 150px;
        @media screen and (max-width: breakpoint(phone-lg)) {
          font-size: 40pt;
        }
      }
      .subtitle-intro {
        padding-top: 40px;
      }
      .highlight-bar {
        margin-top: 70px;
      }
    }
  }
  &.is-team {
    .header-wrap {
      padding-bottom: 50px;
      background-color: white;
    }
    .title-intro-container {
      .title-intro {
        font-size: 40pt;
        margin-top: 150px;
        @media screen and (max-width: breakpoint(phone-lg)) {
          font-size: 40pt;
        }
      }
      .subtitle-intro {
        padding-top: 40px;
      }
      .highlight-bar {
        margin-top: 70px;
      }
    }
  }
  &.is-blog {
    .header-wrap {
      background-color: color(primary);
    }
    .title-intro-container {
      .title-intro {
        font-size: 40pt;
        margin-top: 20px;
        padding-bottom: 150px;
        @media screen and (max-width: breakpoint(phone-lg)) {
          font-size: 40pt;
        }
      }
      .subtitle-intro {
        padding-top: 120px;
      }
      .highlight-bar {
        margin-top: 70px;
      }
    }
  }
  &.is-post {
    .header-wrap {
      height: 150px;
      overflow-y: hidden;
    }
  }
  &.is-error {
    .title-intro-container {
      height: 150px;
      margin-top: 100px;
    }
  }
  &.is-idea {
    .header-wrap {
      overflow-y: hidden;
    }
  }
  .title-intro-container {
    @media screen and (max-width: breakpoint(tablet-lg)) {
      margin-bottom: 90px;
    }
  }
}


/*------------------------------------*\
                ¡MAIN NAV
\*------------------------------------*/

.nav-squeeze {
  @media screen and (max-width: breakpoint(phone-lg)) {
    background-color: color(primary);
    padding-bottom: 1.5rem;;
  }
}

.nav-main {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  margin-top: 3rem;
  padding: 0;
  position: relative;
  z-index: 100;
  @media screen and (max-width: breakpoint(phone-lg)) {
    margin-top: 1.5rem;
  }
  .hamburger {
    cursor: pointer;
  }
  .links {
    @include flexbox();
    @include align-items(center);
    @media screen and (max-width: breakpoint(phone-lg)) {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      background-color: color(primary-dark);
      z-index: 1;
      width: 100vw;
      height: 100vh;
      padding: 7.5rem 1rem 1rem 1.5rem;
    }
    .menu-close {
      cursor: pointer;
      display: none;
      position: absolute;
      top: 26px;
      right: 20px;
    }
    .link {
      margin-left: 3.5rem;
      font-family: font(lato-regular);
      color: color(gray-2);
      text-transform: uppercase;
      &:hover, &.active {
        color: color(accent-yellow);
      }
      @media screen and (max-width: breakpoint(tablet)) {
        margin-left: 2rem;
      }
      @media screen and (max-width: breakpoint(phone-lg)) {
        display: block;
        margin-bottom: 2.5rem;
        font-family: font(lato-bold);
        font-size: 1.625rem;
      }
    }
  }
}

.desktop {
  @media screen and (max-width: breakpoint(phone-lg)) {
    display: none !important;
  }
}
.mobile {
  display: none !important;
  @media screen and (max-width: breakpoint(phone-lg)) {
    display: flex !important;
  }
}




/*------------------------------------*\
            ¡PAGE STRUCTURE
\*------------------------------------*/

.title-intro {
  color: color(primary);
  font-family: font(bebas-bold);
  font-size: 3.75rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
  @media screen and (max-width: breakpoint(phone-lg)) {
    font-size: 3rem;
  }
  &.white {
    color: white;
  }
  &.left {
    text-align: left;
  }
}

.subtitle-intro {
  font-family: font(lato-light);
  color: white;
  text-transform: uppercase;
  text-align: center;
  &.primary {
    color: color(primary);
  }
}



/*------------------------------------*\
              ¡SECTION
\*------------------------------------*/

.section-wrap {
  padding: 60px 0 90px;
  position: relative;
  @media screen and (max-width: breakpoint(tablet)) {
    padding: 40px 0 60px;
  }
  &.bg-primary-light {
    background-color: color(primary-light);
    .section-title {
      color: white;
    }
  }
  &.bg-primary {
    background-color: color(primary);
    .section-title {
      color: white;
    }
  }
  &.bg-white {
    background-color: white;
    .section-title {
      color: color(primary);
    }
  }
}

.section-title {
  color: color(primary);
  font-family: font(bebas-bold);
  font-size: 3rem;
  padding-bottom: 20px;
  padding-top: 20px;
  @media screen and (max-width: breakpoint(tablet-lg)) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: breakpoint(phone-lg)) {
    font-size: 2rem;
  }
  &.white {
    color: white;
  }
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
}

.subtitle {
  color: color(primary);
  font-family: font(lato-bold);
  font-size: 1.5rem;
  line-height: 1.2rem;
  margin-top: 30px;
  text-align: center;
}

.highlight-bar {
  width: 170px;
  height: 3px;
  background-color: color(accent-yellow);
  margin: 40px auto;
}



/*------------------------------------*\
                ¡HOME
\*------------------------------------*/

.reasons-wrap {
  @include flexbox();
  @include justify-content(center);
  @include flex-wrap(wrap);
  margin-top: 60px;
  padding: 0 50px;
  @media screen and (max-width: breakpoint(tablet)) {
    padding: 0;
  }
}

.reason {
  @include flexbox();
  @include flex-direction(column);
  @include align-items(center);
  margin: 20px;
  width: 300px;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  border-radius: 10px;
  .title {
    color: color(primary);
    font-family: font(bebas-bold);
    font-size: 1.875rem;
    letter-spacing: 2px;
    margin: 0;
    padding: 30px 0;
    text-align: left;
    width: 100%;
    text-transform: uppercase;
  }
  img {
    display: block;
    margin: 0 auto;
    height: 100px;
  }
  .paragraph {
    margin-top: 40px;
    text-align: left;
    color: color(primary);
    width: 100%;
  }
}

.why-choose {
  background-repeat: no-repeat; 
  background-position: left; 
  background-size: 50vw;
  // Don't display the background image at small sizes
  background-size: calc(min(60vw, 1000px));

  @media screen and (max-width: breakpoint(tablet-lg)) {
    background-size: 50vw;
  }
  @media screen and (max-width: breakpoint(tablet)) {
    background-size: 0 0;
  }
  .why-choose-content {
    padding: 100px 0 100px;
    max-width: 350px;
    float: right;
    margin-right: 50px;
    @media screen and (max-width: breakpoint(tablet)) {
      max-width: unset;
      float: left;
      margin-right: unset;    }
    p {
      color: white;
      font-size: 14pt;
    }
    a {
      margin-top: 30px;
    }
  }
}

.stats-content {
  @include flexbox();
  @include justify-content(center);
  @include flex-wrap(wrap);
  color: white;
  padding: 50px 0 200px;
  @media screen and (max-width: breakpoint(tablet)) {
    padding: 30px 0 50px;
  }
  .text {
    font-size: 14pt;
    width: 35%;
    padding-right: 10%;
    padding-top: 50px;
    @media screen and (max-width: breakpoint(tablet)) {
      width: 100%;
      padding: 0 0 50px;
    }
    a {
      margin-top: 30px;
    }
  }
  .stat-wrap {
    width: 470px;
    @media screen and (max-width: breakpoint(phone-lg)) {
      width: 300px;
    }
    .stat {
      @include flexbox();
      @include flex-wrap(nowrap);
      @include align-items(center);
      @include justify-content(space-between);
      width: 100%;
      padding: 20px 30px;
      margin: 30px 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, .3);
      border-radius: 10px;
      .number {
        font-size: 40pt;
        font-family: font(bebas-bold);
        width: 50pt;
        margin-right: 20px;
        @media screen and (max-width: breakpoint(phone-lg)) {
          margin-right: 30px;
        }
      }
      .description {
        width: 190pt;
        font-size: 14pt;
        font-family: font(lato-bold);
        margin-right: 20px;
      }
      .icon {
        width: 60px;
        height: 60px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        @media screen and (max-width: breakpoint(phone-lg)) {
          display: none;
        }
      }
    }
  }
}

.testimonials-wrap {
  @include flexbox();
  @include justify-content(center);
  @include flex-wrap(wrap);
  margin-top: 30px;
  padding: 0 50px;
  @media screen and (max-width: breakpoint(tablet)) {
    padding: 0;
  }
}

.testimonial {
  @include flexbox();
  @include flex-direction(column);
  @include justify-content(flex-start);
  @include align-items(flex-start);
  margin: 20px;
  width: 300px;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  border-radius: 10px;
  img {
    border-radius: 50%;
    margin: 0 auto 20px;
    width: 120px;
  }
  .paragraph {
    color: color(primary);
    padding: 0 0 60px;
    width: 100%;
  }
  .author {
    color: color(gray-3);
    font-family: font(leto-regular);
    position: relative;
    text-transform: uppercase;
    top: -40px;
    span {
      font-family: font(lato-regular);
      font-size: 0.75rem;
      text-transform: capitalize;
    }
  }
}

.featured-client-wrap {
  @include flexbox();
  @include flex-wrap(wrap);
  @include justify-content(center);
  margin: 50px 0;
  .featured-client {
    background-repeat: no-repeat; 
    background-position: center; 
    background-size: contain;
    width: calc(min(180px, 30%));
    height: 60px;
    margin: 30px 40px;
  }
}


/*------------------------------------*\
                ¡VIDEOS
\*------------------------------------*/

.is-craft {
  .section-wrap {
    padding-bottom: 0;
    margin-bottom: 100px;
  }
}

.project-wrap {
  
  .project {
    margin: auto;
    max-width: 1000px;
    padding-bottom: 80px;
    color: white;

    .project-name {
      font-family: font(bebas-bold);
      font-size: 30pt;
      padding-bottom: 8px;
    }
    .project-description {
      font-family: font(lato-light);
      text-transform: uppercase;
      font-size: 12pt;
      padding-bottom: 30px;
    }
  }
}

.video {
  position: relative;
  &.is-playing {
    &:hover {
      .video-controls {
        .control-pause {
          opacity: 1;
        }
      }
    }
    .video-controls {
      .control-play {
        opacity: 0;
      }
    }
    .video-data {
      opacity: 0;
    }
  }
  video {
    height: 100%;
    width: 100%;
  }
}

.video-controls {
  @include transform(translate(-50%, -50%));
  height: 56px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 86px;
  .control {
    cursor: pointer;
    height: 56px;
    position: absolute;
    width: 86px;
  }
  .control-pause {
    opacity: 0;
  }
}

.client-wrap {
  @include flexbox();
  @include flex-wrap(wrap);
  @include justify-content(center);
  margin: 50px auto 0;
  max-width: 890px;
}

.client {
  @include flexbox();
  @include gridMachine(3, 2%);
  @include align-items(center);
  @include justify-content(center);
  @media screen and (max-width: breakpoint(tablet)) {
    @include gridMachine(2, 2%);
  }
  @media screen and (max-width: breakpoint(phone-lg)) {
    @include gridMachine(1, 2%);
  }
  height: 120px;
  .client-logo {
    display: block;
    margin: 0 auto;
  }
}


/*------------------------------------*\
                ¡TEAM
\*------------------------------------*/

.employee-wrap {
  @include flexbox();
  @include flex-wrap(wrap);
  margin: 50px auto 0;
  max-width: 890px;
}

.employee {
  @include align-items(center);
  @include flexbox();
  @include flex-direction(column);
  @include gridMachine(4, 3%);
  position: relative;
  &.is-active {
    .employee-trigger {
      .icon {
        @include transform(rotate(0deg));
      }
    }
    .employee-data {
      height: 170px;
      padding-bottom: 10px;
      padding-top: 100px;
      display: flex;
      opacity: 100%;
    }
  }
  @media screen and (max-width: breakpoint(tablet)) {
    @include gridMachine(3, 3%);
  }
  @media screen and (max-width: breakpoint(phone-lg)) {
    @include gridMachine(2, 2%);
  }
}

.employee-trigger {
  margin-bottom: 30px;
  position: relative;
  width: 150px;
  z-index: 2;
  cursor: pointer;
  .employee-picture {
    display: block;
    margin: 0 auto;
    width: 120px;
    height: 130px;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    @media screen and (max-width: breakpoint(phone-lg)) {
      width: 100px;
    }
  }
  .icon {
    @include transform(rotate(180deg));
    @include transition(transform .5s);
    bottom: -10px;
    cursor: pointer;
    position: absolute;
    right: 0;
    @media screen and (max-width: breakpoint(phone-lg)) {
      right: 20px;
    }
  }
}

.employee-data {
  @include align-items(center);
  @include flexbox();
  @include flex-direction(column);
  @include justify-content(space-between);
  @include transition(all .5s);
  background: white;
  border-radius: 3px;
  height: 0;
  padding: 0 10px;
  position: relative;
  top: -105px;
  width: 120px;
  opacity: 0%;
  z-index: 1;
  @media screen and (max-width: breakpoint(phone-lg)) {
    width: 130px;
  }
  .title {
    color: color(primary);
    font-family: font(lato-bold);
    font-size: 1rem;
    text-align: center;
    text-transform: uppercase;
    span {
      font-family: font(lato-regular);
      line-height: 20pt;
      text-transform: capitalize;
    }
  }
  .paragraph {
    color: color(primary);
    font-family: font(lato-regular);
    font-size: 0.75rem;
    margin: 10px 0 5px;
    text-align: center;
    width: 100%;
  }
  .author {
    color: color(primary);
    font-family: font(lato-light);
    font-size: 0.75rem;
    font-style: italic;
  }
}

.mission-values {
  @include flexbox();
  @include justify-content(space-evenly);
  color: white;
  @media screen and (max-width: breakpoint(tablet)) {
    @include flex-wrap(wrap);
  }
  .col {
    font-size: 14pt;
    padding-top: 50px;
    font-family: font(lato-light);
    padding: 20px;
    max-width: 400px;
    @media screen and (max-width: breakpoint(tablet)) {
      width: 100%;
      padding: 20px 0 20px;
      max-width: unset;
    }
  }
}




/*------------------------------------*\
                ¡BLOG
\*------------------------------------*/

.post-teaser-wrap {
  @include flexbox();
  @include flex-wrap(wrap);
  margin: 50px auto 0;
  @media screen and (max-width: breakpoint(tablet-lg)) {
    padding: 0 100px;
  }
  @media screen and (max-width: breakpoint(tablet)) {
    padding: 0;
  }
}

.post-teaser {
  @include align-items(center);
  @include flexbox();
  @include flex-direction(column);
  @include gridMachine(2, 3%);
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  background: white;
  border-radius: 5px;
  display: block;
  padding: 20px 30px 50px;
  position: relative;
  text-decoration: none;
  @media screen and (max-width: breakpoint(tablet-lg)) {
    @include gridMachine(1, 3%);
  }
  .title {
    color: color(primary);
    font-family: font(bebas-bold);
    font-size: 1.875rem;
    letter-spacing: 1px;
    margin: 25px 0 15px;
    text-align: left;
    text-decoration: none;
  }
  .image {
    border: none;
    display: block;
    width: 100%;
    height: 300px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .paragraph {
    color: color(primary);
    margin: 15px 0 0;
  }
  .icon-logo {
    display: block;
    fill: color(accent-yellow);
    margin: 0 auto;
    max-height: 225px;
  }
}

.post-content {
  letter-spacing: .02rem;
  margin: 0 auto;
  max-width: 700px;
  .post-date {
    font-family: font(lato-regular);
    color: color(gray-2);
    padding-bottom: 5px;
    font-size: 1rem;
    text-transform: uppercase;
  }
  .post-title {
    font-family: font(bebas-bold);
    font-size: 40pt;
    color: color(primary);
  }
  .post-author {
    color: color(primary);
    font-family: font(lato-regular);
    font-size: 1rem;
    text-transform: uppercase;
    padding: 20px 0 80px;
  }
  .post-subtitle {
    font-family: font(lato-bold);
    padding: 0 0 30px;
    color: color(primary-light);
    font-size: 1.5rem;
    text-transform: uppercase;
    text-align: center;
  }
  p {
    color: color(gray-3);
    font-family: font(lato-regular);
    line-height: 1.4rem;
    margin: 0 0 35px;
    img {
      display: block;
      margin: auto;
    }
    h2 {
      font-size: 16pt;
      color: color(primary-light);
      padding: 10px 0 20px;
    }
    h3 {
      font-size: 12pt;
      color: color(primary-light);
      padding-bottom: 15px;
    }
    p {
      padding-bottom: 25px;
    }
    a {
      color: color(link-blue);
      font-family: "Lato", sans-serif;
      cursor: pointer;
      text-decoration: none;
    }
    a:hover {
      color: color(link-blue-hover);
    }
    strong {
      font-family: font(lato-bold);
    }
  }

}


/*------------------------------------*\
              ¡IDEA TOOL
\*------------------------------------*/

.is-idea {
  .header-wrap {
    .idea-banner {
      @include transform(translate(-50%, -50%));
      left: 50%;
      opacity: .25;
      position: absolute;
      top: 50%;
      width: 100%;
      @media screen and (max-width: breakpoint(tablet-lg)) {
        width: auto;
      }
    }
  }
  .error {
    border: 1px solid #cc0000;
  }
  .error-message {
    color: #cc0000;
  }
}

.idea-content {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 800px;
  color: color(primary);
  font-family: font(lato-light);
  .header {
    .tagline {
      font-size: 1.20rem;
      color: color(gray-3);
      max-width: 400px;
      text-align: center;
      margin: auto;
    }
  }
  .sub-header {
    .title {
      font-family: font(bebas-bold);
      font-size: 2rem;
      text-transform: uppercase;
      color: color(accent-teal);
    }
    .sub-title {
      font-family: font(lato-regular);
      color: color(gray-3);
      text-align: right;
      text-transform: uppercase;
      font-size: 12pt;
    }
  }
  .field {
    padding: 50px 0;
    ~.field {
      border-top: 1px solid color(grey);
    }
    .question {
      font-size: 13pt;
      font-family: font(lato-bold);
    }
  }

}

.text-right {
  text-align: right;
}

.action-group {
  @include flexbox();
  @include justify-content(space-between);
}

.top-right {
  position: absolute;
  top: 10px;
  right: 50px;
  height: 100px;
  width: 100px;
}

.top-left {
  position: absolute;
  top: 10px;
  left: 50px;
  height: 100px;
  width: 100px;
}

@media print {
  .top-left {
    display: none;
  }
  .top-right {
    display: none;
  }
}





/*------------------------------------*\
                ¡CONTACT
\*------------------------------------*/

.contact-wrap {
  background: white;
  margin-top: 100px;
  .section-title {
    margin: 0 0 50px;
    @media screen and (max-width: breakpoint(tablet)) {
      margin: 0 0 30px;
    }
  }
}

.contact-content {
  @include flexbox();
  @include justify-content(center);
  @media screen and (max-width: breakpoint(tablet)) {
    @include align-items(center);
    @include flex-direction(column);
  }
  max-width: 1000px;
  margin: auto;
  .contact-text-wrap {
    .section-title {
      margin: 0;
    }
    .contact-info-block {
      font-size: 13pt;
      font-family: font(lato-bold);
      color: color(gray-3);
      line-height: 130%;
      margin: 50px 0;
      .info {
        a {
          font-family: font(lato-regular);
          text-decoration: none;
          color: inherit;
        }
      }
      .info~.info {
        margin-top: 30px;
      }
    }
  }
  form {
    margin: 0 0 0 100px;
    width: 80vw;
    @media screen and (max-width: breakpoint(tablet)) {
      margin: 0 auto;
      width: 100%;
    }
  }
}


/*------------------------------------*\
                 ¡404
\*------------------------------------*/

.is-error {
  .message{
    text-align: center;
    p {
      padding: 50px 0;
      font-size: 14pt;
      text-align: center;
    }
  }
}


/*------------------------------------*\
                ¡FOOTER
\*------------------------------------*/

.footer {
  background-color: white;
  font-family: font(lato-regular);
  color: color(gray-2);
  z-index: 5;
  margin-top: 100px;
  .crown {
    color: white;
    margin-bottom: -120px;
    display: block;
    z-index: 1;
    .box {
      @include flexbox();
      @include justify-content(space-between);
      @media screen and (max-width: breakpoint(tablet)) {
        @include align-items(center);
        @include flex-direction(column);
      }
      background-color: color(primary-light);
      border-radius: 30px;
      padding: 40px 100px;
      margin: 0 50px 50px;
      @media screen and (max-width: breakpoint(phone-lg)) {
        padding: 30px; 
        margin: 30px;
      }
    }
    .left{
      .section-title {
        padding: 10px 0;
      }
    }
    .right {
      a {
        margin: 10% 0 0 20px;
        width: 200px;
        text-align: center;
      }
    }
  }
  .top {
    @include flexbox();
    @include justify-content(space-between);
    box-shadow: 0px -3px 10px rgba(0,0,0,0.1);
    padding: 200px 10%;
    height: 445px;
    @media screen and (max-width: breakpoint(phone-lg)) {
      display: none;
    }
    .info {
      @include flexbox();
      .link {
        display: block;
        text-transform: uppercase;
        margin-bottom: 1rem;
        color: color(gray-2);
        &.info {
          text-transform: lowercase;
          margin-left: 100px;
        }
        .icon {
          margin-right: 1.5rem;
        }
      }
      .link:hover {
        color: color(primary-light);
      }
      .link:active {
        color: color(primary);
      }
    }
  }
  .base {
    @include flexbox();
    @include align-items(center);
    height: 200px;
    position: relative;
    padding: 0 10%;
    border-top: 1px solid color(gray);
    @media screen and (max-width: breakpoint(phone-lg)) {
      @include justify-content(center);
      @include flex-direction(column);
      font-size: 0.625rem;
      padding: 180px 0 100px;
    }
    .icon-to-top {
      position: absolute;
      top: -24px;
      right: 24px;
      z-index: 2;
    }
    .logo {
      margin-bottom: 2.5rem;
    }
  }
}
