/*------------------------------------*\
              ¡BUTTONS
\*------------------------------------*/

.btn {
    border-radius: 30px;
    cursor: pointer;
    display: inline-block;
    font-family: font(lato-light);
    font-weight: 700;
    text-transform: uppercase;
    padding: .625em 2em;
    text-decoration: none;
    background: none;
    font-size: 14pt;
    text-align: center;
    
    &.accent-teal {
        color: color(accent-teal);
        border: solid color(accent-teal) .1em;
    }
    &.accent-yellow {
        color: color(accent-yellow);
        border: solid color(accent-yellow) .1em;
    }
}
