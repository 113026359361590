/*------------------------------------*\
              ¡TYPOGRAPHY
\*------------------------------------*/

@font-face {
    font-family: 'Bebas Bold';
    src: url('../fonts/bebasneue_bold.eot');
    src: url('../fonts/bebasneue_bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue_bold.woff2') format('woff2'),
         url('../fonts/bebasneue_bold.woff') format('woff'),
         url('../fonts/bebasneue_bold.ttf') format('truetype'),
         url('../fonts/bebasneue_bold.svg#bebasneue_bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Regular';
    src: url('../fonts/bebasneue_regular.eot');
    src: url('../fonts/bebasneue_regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue_regular.woff2') format('woff2'),
         url('../fonts/bebasneue_regular.woff') format('woff'),
         url('../fonts/bebasneue_regular.ttf') format('truetype'),
         url('../fonts/bebasneue_regular.svg#bebasneue_regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Light';
    src: url('../fonts/bebasneue_light.eot');
    src: url('../fonts/bebasneue_light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue_light.woff2') format('woff2'),
         url('../fonts/bebasneue_light.woff') format('woff'),
         url('../fonts/bebasneue_light.ttf') format('truetype'),
         url('../fonts/bebasneue_light.svg#bebasneue_light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Bold';
    src: url('../fonts/lato-bold.eot');
    src: url('../fonts/lato-bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-bold.woff2') format('woff2'),
         url('../fonts/lato-bold.woff') format('woff'),
         url('../fonts/lato-bold.ttf') format('truetype'),
         url('../fonts/lato-bold.svg#latobold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Bold Italic';
    src: url('../fonts/lato-bolditalic.eot');
    src: url('../fonts/lato-bolditalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-bolditalic.woff2') format('woff2'),
         url('../fonts/lato-bolditalic.woff') format('woff'),
         url('../fonts/lato-bolditalic.ttf') format('truetype'),
         url('../fonts/lato-bolditalic.svg#latobold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Light';
    src: url('../fonts/lato-light.eot');
    src: url('../fonts/lato-light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-light.woff2') format('woff2'),
         url('../fonts/lato-light.woff') format('woff'),
         url('../fonts/lato-light.ttf') format('truetype'),
         url('../fonts/lato-light.svg#latolight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Regular';
    src: url('../fonts/lato-regular.eot');
    src: url('../fonts/lato-regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-regular.woff2') format('woff2'),
         url('../fonts/lato-regular.woff') format('woff'),
         url('../fonts/lato-regular.ttf') format('truetype'),
         url('../fonts/lato-regular.svg#latoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

html {
	font-size: 16px;
}

h1,h2,h3,h4,h5,h6{
    font-weight: normal;
}

// No long setting the H1 - H6 font sizes. Should be done on a component basis
