/*------------------------------------*\
              ¡IDEA FORM
\*------------------------------------*/

.idea-form {
	margin: 10px 0 0;
	.label {
		display: block;
	}
	.input {
		display: block;
		font-family: font(lato-regular);
		font-size: 1rem;
		height: 40px;
		padding: 0 5px;
		-webkit-appearance: none;
		width: 100%;
		border: 1px solid color(gray-2);
	}
	.textarea {
		display: block;
		font-size: 1rem;
		font-family: font(lato-regular);
		height: 150px;
		max-height: 150px;
		max-width: 100%;
		padding: 5px;
		-webkit-appearance: none;
		width: 100%;
		border-color: color(gray-2);
	}
	.checkbox {
	  cursor: pointer;
	  display: inline-block;
	  padding-left: 24px;
	  position: relative;
	  input {
	    position: absolute;
	    opacity: 0;
	    z-index: -1;
	  }
	  span {
	  	border: solid 1px color(gray-2);
	    display: block;
	    height: 20px;
	    left: 0;
	    position: absolute;
	    top: 0;
	    width: 20px;
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
	  }
	  input:checked ~ span {
	    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAANCAYAAAB2HjRBAAAAAXNSR0IArs4c6QAAAK9JREFUKBVjYCAR/N/DUAvETSBtLKToBWlk+A/RCGQzMBGrGVkjVM9vovSCNP7fzfAfjkEuIAbQVuP/vQyBQBt8kF1ClI1Qjb+A/vkJM4AojUCbWBj+MUQAaVawrf8Z1gI1rgZGRzTcFYwMdYwuDM1wPhKD8f8ZoMYPDCuBGgKRxCFMPBpBChhBBA4DehhdGUpB8gQByACgk9eB43IPw+L//4lPQGDDwQbsZcghViMAsY6Al6ntRNsAAAAASUVORK5CYII=');
	  	background-position: 50%;
	  	background-repeat: no-repeat;
	  	background-size: 16px;
	  }
	}
	.radio {
	  position: relative;
	  display: inline;
	  padding-left: 24px;
	  cursor: pointer;
	  input {
	    position: absolute;
	    opacity: 0;
	    z-index: -1;
	  }
	  span {
	  	border: solid 1px color(gray-2);
	    border-radius: 50%;
	    display: block;
	    height: 20px;
	    left: 0;
	    position: absolute;
	    top: 0;
	    width: 20px;
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
	  }
	  input:checked ~ span {
	    background: transparent;
	  }
	  input:checked ~ span:after {
	    background: color(gray-2);
	    border-radius: 50%;
	    content: '';
	    display: block;
	    height: 12px;
	    left: 3px;
	    position: absolute;
	    top: 3px;
	    width: 12px;
	    z-index: 1;
	  }
	}
	.select {
		border: solid 1px color(gray-1);
		display: inline-block;
		position: relative;
		width: 100%;
		select{
			background: color(gray-1);
			border: 0;
			border-radius: 0;
			cursor: pointer;
			display: inline-block;
			font-size: 1rem;
			height: 40px;
			line-height: 1.5;
			margin: 0;
			padding: 5px 35px 5px 10px;
			outline: 0;
			width: 100%;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}
		&:after {
			border-color: color(accent-yellow);
			border-style: solid;
			border-width: 2px 2px 0 0;
			content: '';
			display: inline-block;
			height: 16px;
			right: 10px;
			position: absolute;
			top: 5px;
			transform: rotate(135deg);
			width: 16px;
		}
	}
}


/*------------------------------------*\
              ¡FORMS
\*------------------------------------*/

.input-group {
	margin: 10px 0 0;
	.label {
		display: block;
		color: color(gray-1);
	}
	.input,.textarea {
		border: solid 1px color(gray-2);
		display: block;
		font-family: font(lato-light);
		font-size: 1.2rem;
		padding: 15px;
		-webkit-appearance: none;
		width: 100%;
		margin: 20px 0;
	}
	.input {
		height: 40px;
	}
	.textarea {
		padding-top: 10px;
		height: 150px;
		max-height: 150px;
		max-width: 100%;
	}
	.checkbox {
	  cursor: pointer;
	  display: inline-block;
	  padding-left: 24px;
	  position: relative;
	  input {
	    position: absolute;
	    opacity: 0;
	    z-index: -1;
	  }
	  span {
	  	border: solid 1px color(gray-1);
	    display: block;
	    height: 20px;
	    left: 0;
	    position: absolute;
	    top: 0;
	    width: 20px;
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
	  }
	  input:checked ~ span {
	    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAATtJREFUeNrs18sNgzAMBuCECbpE7z10ATaht47UW7tJF+iBe5foCAUUUEp5JI6xHeRfQgiQiL9DwDZGo9FopOZ8ex/aw79nc0Q0p6e7LF/X4yc7iIc4uVt1j7EZI4yPsZkjBkyxA0QHsTtAPJo9crF7QIj9asUiREIgCHEQKEIUJAUhBpKKEAHBQLBDsBCsEEwEGwQbwQLZAkEO2QrxB2kWurdnyIs4ET8Qh6hSX8iBGCAjhMHEUCA6yAwCZQEqRJtiZZGq3zeSET2kdAM8GoYa4e8RtIU5EOOvVnIBXIip/wi4EE7E5J8dUhA3YrZFiSlMAmKx1wot0D1nRaw2jYEYw40I6n4TMGSI4DYegCFFRM0jERhyRPRgFYBhQYAmxAUMGwI86k5gWBFJM7uHqbkRWCOsRqPR0OUrwADU0PN/fzhQbgAAAABJRU5ErkJggg==');
	  	background-position: 50%;
	  	background-repeat: no-repeat;
	  	background-size: 16px;
	  }
	}
	.radio {
	  position: relative;
	  display: block;
	  padding-left: 24px;
	  cursor: pointer;
	  input {
	    position: absolute;
	    opacity: 0;
	    z-index: -1;
	  }
	  span {
	  	border: solid 1px color(gray-1);
	    border-radius: 50%;
	    display: block;
	    height: 20px;
	    left: 0;
	    position: absolute;
	    top: 0;
	    width: 20px;
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
	  }
	  input:checked ~ span {
	    background: transparent;
	  }
	  input:checked ~ span:after {
	    background: color(accent-yellow);
	    border-radius: 50%;
	    content: '';
	    display: block;
	    height: 12px;
	    left: 3px;
	    position: absolute;
	    top: 3px;
	    width: 12px;
	    z-index: 1;
	  }
	}
	.select {
		border: solid 1px color(gray-1);
		display: inline-block;
		position: relative;
		width: 100%;
		select{
			background: color(gray-1);
			border: 0;
			border-radius: 0;
			cursor: pointer;
			display: inline-block;
			font-size: 1rem;
			height: 40px;
			line-height: 1.5;
			margin: 0;
			padding: 5px 35px 5px 10px;
			outline: 0;
			width: 100%;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}
		&:after {
			border-color: color(accent-yellow);
			border-style: solid;
			border-width: 2px 2px 0 0;
			content: '';
			display: inline-block;
			height: 16px;
			right: 10px;
			position: absolute;
			top: 5px;
			transform: rotate(135deg);
			width: 16px;
		}
	}
}


.g-recaptcha {
	@include transform(scale(0.85));
	margin: 10px 0 10px -41px;
}
